import Common from "./common.js"

class Form extends Common{
  constructor() {
    super();
    var CI = this;
    this.token = ''
    this.validate("#dealform")
    this.getFormDetails('#dealform')
    this.showToolTip()
    this.fillform()
    this.popupPartners()
    this.toggleIcon()
    this.showTab(this.currentTab);
    var chk1 = $("input[type='checkbox'][name='agree']");
    var chk2 = $("input[type='checkbox'][name='partners-tp']");

    chk1.on('change', function(){
     chk2.prop('checked',this.checked);
    });
    var focusbtn = document.getElementById('focusButton')
    if (focusbtn) {
      document.getElementById('focusButton').addEventListener('click', function() {
        var input = document.getElementById('postcode');
        input.scrollIntoView({ behavior: 'smooth', block: 'center' });
        input.focus();
      });
    }

    document.addEventListener("DOMContentLoaded", function () {
      const questionElements = document.querySelectorAll("[data-action='click->form#toggleAnswer']");
      questionElements.forEach(function (questionElement) {
        questionElement.addEventListener("click", function () {
          const answerId = this.getAttribute("data-id");
          const answerElement = document.getElementById("answer" + answerId);
          const crossIconElements = document.querySelectorAll("[data-target='form.crossIcon'][data-id='" + answerId + "']");
          answerElement.classList.toggle("answer-visible");
          crossIconElements.forEach(function (crossIconElement) {
            crossIconElement.classList.toggle("tw-opacity-0");
          });
        });
      });
    });

    // $( ".property" ).click(function() {
    //   var postcodeDiv = document.querySelector(".postcode-step");
    //   if (postcodeDiv) {
    //     var loader = document.querySelector(".step-loader");
    //     postcodeDiv.classList.add("tw-opacity-30")
    //     loader.classList.remove("tw-hidden")
    //   }
    //   CI.nextStep(1);
      
    //   $('.towncity').val($(this).find("option:selected").data("city"))
    //   $('.county').val($(this).find("option:selected").data("province"))
    //   $('.street1').val($(this).find("option:selected").data("street"))
    //   $('.fullAddress').val($(this).find("option:selected").data("fulladdress"))
    //   $(".flatNumber").val($(this).find("option:selected").data("flat"));
    //   $(".uprn").val($(this).find("option:selected").data("uprn"));
    //   $(".mpan").val($(this).find("option:selected").data("mpan"));
    //   $('.building').val($(this).find("option:selected").data("building"))
    //   $(".header-top").removeClass('tw-hidden');
    //   $(".postcode-show").html($(".postcode").val());
    //   $(".address-top").removeClass('tw-hidden');
    //   const selectedValue = $("#property :selected").val().trim()
    //   $('.temp-property').val(selectedValue)
    // });
    
    $('.buttonAddressList').click(function(event) {
      event.preventDefault();
      $('.propertyDiv').show();
    })

    $('.address-link').click(function(event) {
      event.preventDefault();
      
      $('.address-link').each(function() {
        $(this).removeClass('selected');
      });
      $(this).toggleClass('selected'); // Toggle highlight class
      const selectedAddress = $(this);
      $('.selectedAddress').text(selectedAddress[0].innerText)
      $('.selectedAddressDiv').show();
      $('.towncity').val(selectedAddress.data('city'));
      $('.street1').val(selectedAddress.data('street'));
      $('.fullAddress').val(selectedAddress.data('fulladdress'));
      $('.flatNumber').val(selectedAddress.data('flat'));
      $('.building').val(selectedAddress.data('building'));
      $('.county').val(selectedAddress.data("province"))
      $(".uprn").val(selectedAddress.data("uprn"));
      $(".mpan").val(selectedAddress.data("mpan"));
      $('.propertyDiv').hide(); // Hide after selection
      CI.nextStep(1);
    });
    this.accessToken();

    var next = _.debounce(this.nextStep.bind(this), 100, false);
    var back = _.debounce(this.backStep.bind(this), 100, false);

    $( ".nextStep" ).click(function(event) {
      $(".nextStep").addClass("in-progress")
      CI.nextStep(1)
    });
    $( ".backStep" ).click(back);

    $( ".firstStep" ).on('click',function (){
      const pstcodeBtn = document.querySelector(".postcodeBtn");
      const firstStepBtn = document.querySelector(".firstStep");
      if (pstcodeBtn) {
        firstStepBtn.classList.add("tw-hidden")
        pstcodeBtn.classList.remove("tw-hidden")
      }
    });


    $( ".energyprovider" ).change(function() {
      if ($("input[name='energyprovider']:checked").val() !== '' && $("input[name='energyprovider']:checked").val() !== undefined && $("input[name='fuel_type']:checked").val() !== "" && $("input[name='fuel_type']:checked").val() !== undefined) {
        CI.nextStep(1);
      }
    });

    $( ".currently-payment-type" ).change(function() {
      if ($("input[name='currently-payment-type']:checked").val() !== "" && $("input[name='currently-payment-type']:checked").val() !== undefined && $("#residential-status :selected").val() !== "") {
        CI.nextStep(1);
      }
    });

    $( ".fuel_type" ).change(function() {
      if ($("input[name='energyprovider']:checked").val() !== '' && $("input[name='energyprovider']:checked").val() !== undefined  && $("input[name='fuel_type']:checked").val() !== "" && $("input[name='fuel_type']:checked").val() !== undefined ) {
        CI.nextStep(1);
      }
    });
    
    $( ".residential-status" ).change(function() {
      if ($("input[name='currently-payment-type']:checked").val() !== "" && $("input[name='currently-payment-type']:checked").val() !== undefined && $("#residential-status :selected").val() !== "") {
        CI.nextStep(1);
      }
    });

    $( ".house_type" ).change(function() {
      if ($("input[name='house_type']:checked").val() !== "" && $("input[name='house_type']:checked").val() !== undefined && $("input[name='bedroom_count']:checked").val() !== "" && $("input[name='bedroom_count']:checked").val() !== undefined) {
        CI.nextStep(1);
      }
    });
    $( ".bedroom_count" ).change(function() {
      if ($("input[name='house_type']:checked").val() !== "" && $("input[name='house_type']:checked").val() !== undefined && $("input[name='bedroom_count']:checked").val() !== "" && $("input[name='bedroom_count']:checked").val() !== undefined) {
        CI.nextStep(1);
      }
    });
    
    $( ".title" ).change(function() {
      if ($("input[name='title']:checked").val() !== "" && $("input[name='first_name']").val() !== "" && $("input[name='last_name']").val() !== "" && $("input[name='phone']").val() !== "") {
        CI.nextStep(1);
      }
    });
  }

  nextStep() {
    var n = 1;
    var CI = this;
    if (CI.currentTab == 0) {
      let randomNumber = CI.getRandomNumber(10, 25);
      console.log(randomNumber);
      $(".randomNumber").text(randomNumber);
      $(".pstcodeText").text($('.postcode').val());
    }
    console.log(CI.currentTab)
    $("#dealform")
      .parsley()
      .whenValidate({
        group: "block-" + CI.currentTab,
      }).done(() => {
      var tabs = $(".tab");
      tabs[CI.currentTab].style.display = "none";
      if (CI.currentTab == 4) {
        CI.showOverlayLoader();
        CI.postData();
      }
      CI.currentTab = CI.currentTab + n;
      console.log("next step" + CI.currentTab)
      if (CI.currentTab >= tabs.length) {
        if (CI.isPhone == true) {
          CI.postDatafull();
        } else {
          $("#dealform").parsley().validate();
        }
        return true;
      }
      CI.showTab(CI.currentTab);
      var form = document.getElementById('dealform');
      if (form) {
        window.scrollTo({ top: form.offsetTop, behavior: 'smooth' });
      }
    });
  }

  showOverlayLoader() {
    const loader = document.getElementById('overlay-loader');
    loader.classList.remove('tw-hidden');
  }

  showTab(n = 0) {
    var tabs = $(".tab");
    if (!tabs[n]) return;
    tabs[n].style.display = "block";
    $(".nextStep").removeClass("in-progress");
  }
  backStep() {
    var CI = this;
    var n = -1;
    if (CI.currentTab > 0) {
      $('.propertyDiv').hide();
      var tabs = $(".tab");
      tabs[CI.currentTab].style.display = "none";
      CI.currentTab = CI.currentTab + n;
      console.log("back Step" + CI.currentTab)
      CI.showTab(CI.currentTab);
    }
  }

  

  sendSlackNotification(error, spxSessionId){
    $.ajax({
      type: "GET",
      url: "/send_slack_notification",
      data: {message: error, hitMatrixId: spxSessionId},
      success: function(data) {
        console.log(data)
      }
    });
  }

  accessToken() {
    let CI = this;  
    $.ajax({
      type: "GET",
      url: "/get_access_token",
      success: function(data) {
        CI.token = data.token;
      },
      error: function (errorMessage) {
        console.log(errorMessage)
      }
    });
  }

  getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  validateApiPostcode() {
    var CI = this; 
    window.Parsley.addValidator("validapipostcode", {
      validateString: function (value) {
        const pstcodeBtn = document.querySelector(".postcodeBtn");
        const firstStepBtn = document.querySelector(".firstStep");
        if (pstcodeBtn) {
          firstStepBtn.classList.add("in-progress", "tw-opacity-20")
        }
        return $.ajax({
          type: "GET",
          url: `https://api.rebelenergy.com/api/v2/addresses/${$(
            ".postcode"
          ).val()}`,
          headers: {
            'Authorization': `Bearer ${CI.token}`
          },
          success: function (json) {
            $(".property-div").show();
            if (json.length > 0) {
              var result = json;
              var addresses = [];
              for (var i = 0; i < result.length; i++) {
                addresses.push(`
                  <a href="#"
                    class="address-link"
                    data-street="${result[i]?.street}"
                    data-city="${result[i]?.posttown}"
                    data-fullAddress="${result[i]?.fullAddress}"
                    data-building="${result[i]?.number}"
                    data-uprn="${result[i]?.uprn}"
                    data-mpan="${result[i]?.mpan}"
                    data-flat="${result[i]?.flat}">
                      ${result[i].fullAddress}
                    </a>
                  `);
              }
              $('#property').html(addresses.join(''));
              $('.propertyDiv').show();
              $('.address-link').click(function(event) {
                event.preventDefault();
                const pstcodeBtn = document.querySelector(".postcodeBtn");
                const firstStepBtn = document.querySelector(".firstStep");
                if (pstcodeBtn) {
                  firstStepBtn.classList.add("tw-hidden")
                  pstcodeBtn.classList.remove("tw-hidden")
                }
                $('.address-link').each(function() {
                  $(this).removeClass('selected');
                });
                $(this).toggleClass('selected'); // Toggle highlight class
                const selectedAddress = $(this);
                $('.selectedAddress').text(selectedAddress[0].innerText)
                $('.selectedAddressDiv').show();
                $('.towncity').val(selectedAddress.data('city'));
                $('.street1').val(selectedAddress.data('street'));
                $('.fullAddress').val(selectedAddress.data('fulladdress'));
                $('.flatNumber').val(selectedAddress.data('flat'));
                $('.building').val(selectedAddress.data('building'));
                $('.county').val(selectedAddress.data("province"))
                $(".uprn").val(selectedAddress.data("uprn"));
                $(".mpan").val(selectedAddress.data("mpan"));
                $('.propertyDiv').hide();                
              });
              $(".address-div").remove();
              return true;
            } else {
              return $.Deferred().reject("Please Enter Valid Postcode");
            }
          },
          error: function (request) {
            console.log(request.statusText);
            request.abort();
            if (request.statusText == "timeout") {
              var spxSessionId = localStorage.getItem('spxSessionId');
              var hitMatrixURL = `https://cp-inst47-client.phonexa.uk/p8/hitmetrix/recordings/play?sessionId=${spxSessionId}`
              CI.sendSlackNotification(request, "Address LookUp switchenergy Home Hit-Matrix-Id: " + hitMatrixURL)
              $(".property-div").remove();
              $(".address-div").show();
              $("#postcode").removeAttr('data-parsley-required');
              $("#postcode").removeAttr('required');
              $(".postcode").removeClass('error');
              $(".postcode").addClass('valid');
              $(".postcode-error").remove();
              $(".parsley-error-list").remove();
              $(".property-dropdown").show();
              CI.apiDown = true;
            }
          },
          timeout: 10000,
        });
      },
      messages: {
        en: "Please Enter Valid Postcode",
      },
    });
  }

  getData() {
    var spxSessionId = localStorage.getItem('spxSessionId');
    if (spxSessionId) {
      $(".spxSessionId").val(spxSessionId)
    }
    var urlWithParams = window.location.href;
    var bedroom_numbers = document.getElementById('total-bedrooms');
    if (bedroom_numbers) {
      bedroom_numbers = bedroom_numbers.innerText
    }
    if (bedroom_numbers <= 0) {
      bedroom_numbers = $("input[name='bedroom_count']:checked").val()
    }
    return {
      urlWithParams: urlWithParams,
      spxSessionId: spxSessionId || $(".spxSessionId").val() || "",
      postcode: $(".postcode").val() || this.getUrlParameter("postcode") || "",
      firstname:
        $(".first_name").val() || this.getUrlParameter("firstname") || "",
      lastname: $(".last_name").val() || this.getUrlParameter("lastname") || "",
      email: $(".email").val() || this.getUrlParameter("email") || "",
      phoneNumber: $(".phone").val() || this.getUrlParameter("phone1") || "",
      street1: $(".street1").val() || this.getUrlParameter("street1") || "",
      building: $(".building").val() || this.getUrlParameter("building") || "",
      title:
        $("input[name='title']:checked").val() ||
        this.getUrlParameter("title") ||
        "Mr",
      lead_id: this.getUrlParameter("lead_id") || "unknown",
      sid: this.getUrlParameter("sid") || 1,
      source:
        this.getUrlParameter("source") ||
        this.details.source ||
        "Google-Mobile",
      keyword: this.getUrlParameter("keyword") || "",
      ssid: this.getUrlParameter("ssid") || this.details.ssid || "unknown",
      paymentmethod:
        $("input[name='currently-payment-type']:checked").val() ||
        this.getUrlParameter("paymentmethod") ||
        "",
      currentprovider:
        $("#energy-provider option:selected").val() ||
        $("input[name='energyprovider']:checked").val() ||
        this.getUrlParameter("currentprovider") ||
        "",
        residentialStatus:
        $("#residential-status option:selected").val() ||
        $("input[name='residential-status']:checked").val() ||
        this.getUrlParameter("residentialStatus") ||
        "",
      prize: this.getUrlParameter("prize") || "2",
      trafficid: this.getUrlParameter("trafficid") || "switchenergy",
      towncity: $(".towncity").val() || this.getUrlParameter("towncity") || "",
      optindate: this.getFormattedCurrentDate(),
      optinurl: this.details.optinUrl || "http://switchenergy.today/p1",
      ipaddress: this.details.ipaddress,
      apiDown: this.apiDown,
      url_with_params: window.location.href,
      uu_id: this.details.uu_id || "",
      uprn: this.getUrlParameter('uprn') || $(".uprn").val() || "",
      mpan: this.getUrlParameter('mpan') || $(".mpan").val() || "",
      lead_from_local_storage: this.userStorage,
      campaign: this.getUrlParameter("campaign") || "",
      adopted_url: this.adoptedUrl,
      clickid: this.getUrlParameter('gclid') || "",
      adclid: this.getUrlParameter('adclid') || "",
      gclid: this.getUrlParameter("gclid") || $(".gclid").val() || "",
      keyword: this.getUrlParameter("keyword") || $(".keyword").val() || "",
      ad_set: this.getUrlParameter("ad_set") || $(".ad_set").val() || "",
      mkwid: this.getUrlParameter("mkwid") || $(".mkwid").val() || "",
      monthly_bill: $("#monthly_bill").val(),
      fuelType: this.getUrlParameter("fuelType") || $("input[name='fuel_type']:checked").val(),
      buildingType: this.getUrlParameter("buildingType") || $("input[name='house_type']:checked").val(),
      bedrooms : bedroom_numbers || "",
      county: $(".county").val() || this.getUrlParameter('county') || "",
      matchType: this.getUrlParameter("matchType") || $(".matchType").val() || "",
      adGroupId: this.getUrlParameter("adGroupId") || $(".adGroupId").val() || "",
      fullAddress: this.getUrlParameter("fullAddress") || $(".fullAddress").val() || "",
      flatNumber: this.getUrlParameter("flatNumber") || $(".flatNumber").val() || "",
      pubId: this.getUrlParameter("pubId") || $("input[name='pubId']").val() || "",
      ttclid: this.getUrlParameter("ttclid") || "",
    };
  }


  postData() {
    var CI = this;
    this.firePixel();
    // $('#new_page').addClass('d-none');
    // $('.footer_page').addClass('d-none');
    // $('#loading-page').removeClass('d-none');
    // $('#loader-content').html($(".postcode").val());
    $(".tab").removeClass("in-progress");
    console.log("Postdata: " + new Date());
    CI.redirectUrl()
  }
  postDatafull() {
    var CI = this;
    this.firePixel();
    $(".tab").removeClass("in-progress");
    console.log("Postdata: " + new Date());
    CI.successUrl()
  }

  redirectUrl(){
    var CI = this
    $.ajax({
      type: "POST",
      url: "/submit_lead_redirct",
      data: this.getData(),
      success: function(data) {
        if(data.data.redirect_url != "" &&  data.data.redirect_url != undefined && data.data.redirect_url != null) {
          window.location.href = data.data.redirect_url
        }else if(data.data.rejectUrl != ""  &&  data.data.rejectUrl != undefined && data.data.rejectUrl != null){
          window.location.href = data.data.rejectUrl+'?'+jQuery.param(CI.getData())
          console.log(data)
        }else {
          window.location.href = "/duplicate"
        }
      },
      error: function (textStatus, errorMessage) {
        console.log(textStatus)
        console.log(errorMessage)
      },
      dataType: "json"
    })
  }
  
  successUrl(){
    var CI = this
    $.ajax({
      type: "POST",
      url: "/submit_lead_affid",
      data: this.getData(),
      success: function(data) {
        if(data.data.redirect_url != "" &&  data.data.redirect_url != undefined && data.data.redirect_url != null) {
          window.location.href = data.data.redirect_url
        }else if(data.data.rejectUrl != ""  &&  data.data.rejectUrl != undefined && data.data.rejectUrl != null){
          window.location.href = data.data.rejectUrl+'?'+jQuery.param(CI.getData())
          console.log(data)
        }else {
          window.location.href = "/duplicate"
        }
      },
      error: function (textStatus, errorMessage) {
        console.log(textStatus)
        console.log(errorMessage)
      },
      dataType: "json"
    })
  }
}

export default new Form();
